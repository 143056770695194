'use strict';

import Base from './base.js';

export class Region extends Base {

    constructor() {
        super();

        this.changeStatus();
    }

    changeStatus() {

        console.log('Tere');

        $(document).on('click', '.changeStatus', function() {

            jQuery.ajax({
                type: 'POST',
                data: '_token=' + $('[name=_token]').val()+'&id='+$(this).data('id'),
                success: function(result) {


                },
                error: function(){

                    //alert('Mingi jama!');

                },
                url: "/region/setactive",
                cache:false
            });

        });

    }


}

