'use strict';

require('./vendor/bootstrap-datepicker/bootstrap-datepicker.min.js');
require('./vendor/bootstrap-timepicker/bootstrap-timepicker.min.js');
require('./vendor/select2/select2.min.js');
require('./vendor/DataTables/jquery.dataTables.min.js');


export default class Base {

    constructor() {
        this.dateFormat = 'dd.mm.yyyy';
        this.dateRangePickerHandler();
        Base.multipleSelectsHandler();
        Base.timePickerHandler();
        Base.dataTableHandler();
    }


    dateRangePickerHandler() {
        $('.datepicker').datepicker({
            autoclose: true,
            todayHighlight: true,
            weekStart: 1,
            defaultDate: '',
            format: this.dateFormat
        });
    }

    static multipleSelectsHandler() {
        $('.select-multiple').select2({
            allowClear: true
        });
    }

    static timePickerHandler() {
        $('.input-timepicker').timepicker({
            showSeconds: false,
            showMeridian: false,
            defaultTime: '',
            minuteStep: 1
        });
    }

    static dataTableHandler() {

        var theTable = $('.dataTable').dataTable( {lengthMenu: [ 50, 100, 200, 500, "All" ],stateSave: true} );
        
    }


    /**
     * Executes callback after appending row.
     *
     * @param $repeaterElement - jQuery element containing rows and new row button
     * @param {function} rowAddCallback - called after adding a row, passes added row as parameter
     * @param {function} rowRemoveCallback called after deleting a row
     */
    static makeRepeater($repeaterElement,
                        rowAddCallback = () => {},
                        rowRemoveCallback = () => {}) {
        if ($repeaterElement.length > 1) {
            throw 'error: $repeaterElement must match single repeater element!';
        }
        var $repeater = $repeaterElement;
        var $rows = $repeater.find('.repeater-rows');

        $repeater.find('.repeater-add-row')
            .on('click', () => {
                let $row = $rows.find('> *:first').clone();
                $rows.append($row);
                rowAddCallback($row);
                $row.find('.repeater-remove-row')
                    .on('click', () => {
                        rowRemoveCallback($row);
                        $row.remove();
                    });
            });
    }


}

