'use strict';

//require('jquery.dirtyforms');

import Base from './base.js';

export class Tasks extends Base {

    constructor() {
        super();

        Tasks.repeatersHandler();
        Tasks.unknownFieldSetHandler();
        Tasks.dirtyFormHandler();
        this.changeDropdown();
        this.saveData();
        this.updateData();
        this.toggle1();
        this.toggle2();
        this.toggle3();
        this.toggle4();
        this.erply();

        //console.log('blaa');
    }

    static unknownFieldSetHandler() {
        $('[name$="at_unknown"]').on('change', function () {
            // $(this).closest('fieldset').find('input[type="text"]').prop('disabled', this.checked);
        });
    }

    static erply() {
       // $('#add_task').dirtyForms();
    }

    static dirtyFormHandler() {
        

        $('body').on('click', '.removeErply', function(e) {
            
            var task = $(this).data('task');
            var stock = $(this).data('stock');
            var warehouse = $(this).data('warehouse');
            var quantity = $(this).data('quantity');

            console.log('task: ' + task);
            console.log('stock: ' + stock);
            console.log('warehouse: ' + warehouse);
            console.log('quantity: ' + quantity);

            var isGood=confirm('Kindel et kustutan?');
            if (isGood) {

                $(this).closest('tr').remove();

                jQuery.ajax({
                    type: 'POST',
                    data: '_token=' + $('[name$="_token"]').val()+'&productID='+stock+'&warehouse='+warehouse+'&quantity='+quantity+'&task_id='+task,
                    success: function(result) {



                    },
                    error: function(){

                        //alert('Mingi jama!');

                    },
                    url: "/erply/remove-row-from-task",
                    cache:false
                });

            } else {
               // alert('false');
            }



        });

        $('body').on('click', '.removeOtherStock', function(e) {

            var id = $(this).data('id');

            var isGood=confirm('Kindel et kustutan?');
            if (isGood) {

                $(this).closest('tr').remove();

                jQuery.ajax({
                    type: 'POST',
                    data: '_token=' + $('[name$="_token"]').val()+'&id='+id,
                    success: function(result) {



                    },
                    error: function(){

                        //alert('Mingi jama!');

                    },
                    url: "/erply/remove-row-from-task",
                    cache:false
                });

            } else {
                // alert('false');
            }

        });

        $('.add_other_stock_row_new').on('click', function (e) {
            
            var name = $( "#other_stock_name" ).val();
            var quantity = $( "#other_stock_quantity" ).val();
            var task_id = $(this).data('task_id');

            jQuery.ajax({
                type: 'POST',
                data: '_token=' + $('[name$="_token"]').val()+'&name='+name+'&quantity='+quantity+'&task_id='+task_id,
                success: function(result) {

                    // console.log(result.err);

                    if( result.err == 1 )
                    {
                        alert(result.msg);
                    }
                    else
                    {
                        $('#task-materials2 tbody').append(`
                        <tr>
                            <input type="hidden" name="other_id[]" value="`+ result.id +`">
                            <input type="hidden" name="other_quantity[]" value="`+ quantity +`">
                            <input type="hidden" name="other_name[]" value="`+ name +`">
                            <td>`+ name +`</td>
                            <td><strong>`+ quantity +`</strong></td>
                            <td><i class="fa fa-close removeOtherStock" data-id="`+ result.id +`"></i></td>
                        </tr>`);
                    }



                },
                error: function(){

                    //alert('Mingi jama!');

                },
                url: "/other/add-row-to-task",
                cache:false
            });


        });



        $('#task-materials1').on('click', '.remove', function () {
            $(this).closest('tr').remove();
        });

        $('.getErply').on('click', function (e) {

            console.log('Nupp üleval!');

            var warehouse_id = $(this).data('warehouse_id');

            jQuery.ajax({
                type: 'POST',
                data: '_token=' + $('[name$="_token"]').val()+'&string='+$('#string').val()+'&warehouse_id='+warehouse_id,
                success: function(result) {

                   // location.reload();
                    $( "#stockCombo" ).html("");

                    //alert(result.length);

                    $.each(result, function(k, v) {
                       // console.log(k+': '+v.name);
                        $( "#stockCombo" ).append( "<option value='"+v.productID+"' data-warehouse='"+v.warehouseID+"' data-name='"+v.name+"'>"+v.name+" / "+v.code+" (Ladu: "+v.warehouseID+", saadaval:"+v.free+")</option>" );

                        //$( "#result" ).append( "<tr><td><small>"+v.name+"</small></td><td class='col-md-1'><input type='text' name='' value='' class='form-control col-md-1 text-center' /></td><td><input class='btn' type='button' name='b' value='Vali'/></td></tr>" );
                    });




                },
                error: function(){

                    //alert('Mingi jama!');

                },
                url: "/erplyuserstock",
                cache:false
            });

        });

    }

    static repeatersHandler() {
        var $repeaters = $('.repeater-container');
        for (let repeater of $repeaters) {
            let $repeater = $(repeater);
            super.makeRepeater($repeater, ($row) => {
                // optional callback function
                let materialName = $repeater.find('option:selected').attr('data-name');
                let materialId = $repeater.find('option:selected').val();
                let qty = $repeater.find('input[name="quantity[]"]').val();
                $('#task-materials tbody').append(`
                <tr>
                    <input type="hidden" name="stock[]" value="${materialId}">
                    <input type="hidden" name="quantity[]" value="${qty}">
                    <td>${materialName}</td>
                    <td>${qty}</td>
                    <td><i class="fa fa-close remove"></i></td>
                </tr>`);
                $row.remove();
            }, () => {

            });
        }
        $('#task-materials').on('click', '.remove', function () {
            $(this).closest('tr').remove();
        })
    }

    toggle1() {

        $('#not_started').on('click', function (e) {

            // console.log('Klikiti ei käivitunud');

            $("#times").toggle();

        });

    }

    toggle2() {

        $('#stopped_at_unknown').on('click', function (e) {

            //console.log('Klikiti ei käivitunud');

            $("#times1").toggle();

        });

    }

    toggle3() {

        $('#turbiini_ei_lylitatud').on('click', function (e) {

            //console.log('Klikiti ei käivitunud');

            $("#a1").toggle();

        });

    }

    toggle4() {

        $('#exec_had_30_minute_lunch').on('click', function (e) {

            $( "#no_lunch" ).prop( "checked", false );

        });

        $('#no_lunch').on('click', function (e) {

            $( "#exec_had_30_minute_lunch" ).prop( "checked", false );

        });


        $('#works_finished').on('click', function (e) {

            $( "#work_not_finished" ).prop( "checked", false );

        });

        $('#work_not_finished').on('click', function (e) {

            $( "#works_finished" ).prop( "checked", false );

        });

    }

    updateData() {

        $('.update-data').on('click', function (e) {

            var s = $("#add_task").serialize();
            var id = $(this).data('id');

            console.log('Salvestan tööülesande');

            jQuery.ajax({
                type: 'POST',
                data: '_token=' + $('[name=_token]').val() + '&data=' + s,
                success: function (result) {

                    console.log(result);


                    if (result.err == 1) {
                        alert(result.msg);
                    }
                    else {
                       // $('#add_task').dirtyForms('setClean');
                        window.location.href = '/tasks/' + result.id + '/edit';
                    }

                },
                error: function () {

                },
                url: "/tasks/" + id,
                cache: false
            });


        });

    }

    saveData() {

        $('.save-data').on('click', function (e) {

            var s = $("#add_task").serialize();

            console.log('Salvestan tööülesande');

            jQuery.ajax({
                type: 'POST',
                data: '_token=' + $('[name=_token]').val() + '&data=' + s,
                success: function (result) {

                    console.log(result);


                    if (result.err == 1) {
                        alert(result.msg);
                    }
                    else {
                       // $('#add_task').dirtyForms('setClean');
                        window.location.href = '/tasks/' + result.id + '/edit';
                    }

                },
                error: function () {

                },
                url: "/tasks",
                cache: false
            });

            return false;
        });

    }

    changeDropdown() {


        $('#object_id').on('change', function (e) {

            var object_id = e.target.value;

            //ajax

            $.get('/api/unit-dropdown?object+id=' + object_id, function (data) {

                // console.log('aaaaaaaaaaaaaaaa');

                //success data
                $('#unit_id').empty();

                $('#unit_id').append('<option value="0">- - Vali punkt - -</option>');

                $.each(data, function (index, subcatObj) {

                    $('#unit_id').append('<option value="' + subcatObj.id + '">'
                        + subcatObj.name + '</option>');


                });


            });


        });

    }

}

