'use strict';

global.$ = require('jquery');
global.jQuery = require('jquery');

require('./vendor/bootstrap/js/bootstrap.min.js');
require('./vendor/jquery-cookie/jquery.cookie.js');
require('./vendor/perfect-scrollbar/perfect-scrollbar.min.js');

require('./common.js');

window.tasks = require('./tasks.js');
window.users = require('./users.js');
window.materials = require('./materials.js');
window.region = require('./region.js');