'use strict';

import Base from './base.js';
require('./vendor/pwstrength-bootstrap/pwstrength-bootstrap.js');
var generatePassword = require('password-generator');

export class Users extends Base {

    constructor() {
        super();

        this.$pass = $('#passwd1');
        this.$passConfirm = $('#passwd2');
        this.$passBtn = $('#generate-pass');
        this.$passResult = $('#generated-pass');

        this.passwordLogicHandler();
        this.changeUserStatus();

    }

    passwordLogicHandler() {
        this.$pass.pwstrength({
            ui: {showVerdictsInsideProgressBar: true}
        });

        this.$passBtn.click(() => {
            let pass = generatePassword(12);
            this.$pass.val(pass);
            this.$pass.pwstrength("forceUpdate");
            this.$passConfirm.val(pass);
            this.$passResult.html(pass);
        });

    }

    static passwordGenerator(){

    }

    changeUserStatus() {

        console.log('Tere');

        $(document).on('click', '.changeStatus', function() {

                jQuery.ajax({
                    type: 'POST',
                    data: '_token=' + $('[name=_token]').val()+'&id='+$(this).data('id'),
                    success: function(result) {


                    },
                    error: function(){

                        //alert('Mingi jama!');

                    },
                    url: "/users/setactive",
                    cache:false
                });

        });

    }


}

