'use strict';

import Base from './base.js';

export class Materials extends Base {

    constructor() {
        super();
    }

}

